export const alphaNumSortCompare = function(a, b) {
  const splitA = a.replace(/[,\.]/, "").match(/(^\d+)(.+)/);
  const splitB = b.replace(/[,\.]/, "").match(/(^\d+)(.+)/);
  if(splitA && splitB) {
    return parseInt(splitA[0]) - parseInt(splitB[0]) || (splitA[1] > splitB[1] ? 1 : -1);
  }
  return a > b ? 1 : -1;
};

// expects a and b to be collection objects with a name attribute
export const collectionNameSort = (a, b) => {
  // Regular expression to match the pattern "Prefix Number"
  const regex = /(\D*)(\d*)/;
  const matchA = a.name.match(regex);
  const matchB = b.name.match(regex);

  // Extract prefix and number from both strings
  const prefixA = matchA[1], numberA = parseInt(matchA[2], 10);
  const prefixB = matchB[1], numberB = parseInt(matchB[2], 10);

  // Compare prefixes if they are different
  if (prefixA !== prefixB) {
      return prefixA.localeCompare(prefixB);
  }

  // If one string has a number and the other does not, the one with the number goes last
  if (isNaN(numberA) && !isNaN(numberB)) return -1;
  if (!isNaN(numberA) && isNaN(numberB)) return 1;

  // If both have numbers, compare numerically
  if (!isNaN(numberA) && !isNaN(numberB)) {
      return numberA - numberB;
  }

  // If both are purely non-numerical or identical, compare alphabetically
  return a.name.localeCompare(b.name);
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const splitTextOnCloze = (text) => {
  const parts = text.split('{{');
  const pre = parts[0];
  const cloze = (parts[1] || "").split('}}')[0];
  const post = (parts[1] || "").split('}}')[1] || "";
  return { preClozeStr: pre, cloze, postClozeStr: post };
};

export const getLocalStorageBoolean = (key, fallback) => {
  const boolean = window.localStorage && window.localStorage.getItem(key)
  if(!boolean) {
    return fallback;
  }
  return boolean === 'true';
};

export const setLocalStorageBoolean = (key, boolean) => {
  window.localStorage && window.localStorage.setItem(key, boolean.toString());
};

export const secondsSinceEpoch = () => {
  return Math.round(new Date() / 1000);
};

export const pctStr = (a, b) => {
  if(!b) {
    return '0%';
  }
  return Math.round((a / b * 100) * 1000) / 1000 + '%';
};

export const collectionPctStr = (collection, attr) => {
  const num = collection['num' + attr.charAt(0).toUpperCase() + attr.slice(1)];
  return pctStr(num, collection.numSentences);
};

export function secondsToHHMMSSStr(s) {
  const hours = Math.floor(s / 3600);
  const remaining = s % 3600;
  const minutes = Math.floor(remaining / 60);
  const seconds = remaining % 60;
  return hours + ":" + (minutes < 10 ? '0' : '') + minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
};

// https://gist.github.com/jlbruno/1535691/db35b4f3af3dcbb42babc01541410f291a8e8fac
export function ordinalSuffix(n) {
  if(n === 0) return '';

  switch (n % 10) {
    case 1:
      if(n === 11) return 'th';
      return 'st';
    case 2:
      if(n === 12) return 'th';
      return 'nd';
    case 3:
      if(n === 13) return 'th';
      return 'rd';
    default:
      return 'th';
  }
};

export function validateEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

export const LANGUAGE_ACCENT_MAP = {
  ca: {'A':'à', 'C':'ç', 'E':'èé', 'I':'í', 'O':'óò', 'U':'ú' },                 // Catalan
  cs: {'A':'á', 'C':'č', 'D':'ď', 'E':'éě', 'I':'í', 'N':'ň', 'O':'ó', 'R':'ř', 'S':'š', 'T':'ť', 'U':'úů', 'Y':'ý', 'Z':'ž' }, // Czech
  cy: {'A':'áâ', 'E':'éê', 'I':'íî', 'O':'óô', 'W':'ŵ', 'Y':'ŷ' }, // Welsh
  da: {'A':'åæ', 'O':'ø'},                                              // Danish
  de: {'A':'ä', 'O':'ö', 'U':'ü', 'S':'ß'},                             // German
  eo: {'C':'ĉ', 'G':'ĝ', 'H':'ĥ', 'J':'ĵ', 'S':'ŝ', 'U':'ŭ'},           // Esperanto
  es: {'A':'á', 'E':'é', 'I':'í', 'O':'ó', 'U':'úü', 'N':'ñ'},                    // Spanish
  et: {'A':'ä', 'O':'öõ', 'U':'ü'}, // Estonian
  fi: {'A':'åä', 'O':'ö'},                                              // Finnish
  fr: {'A':'àâæ', 'E':'èéêë', 'I':'îï', 'O':'ôœ', 'U':'ùûü', 'C':'ç'},  // French
  ga: {'A':'á', 'E':'é', 'I':'í', 'O':'ó', 'U':'ú'},                    // Irish
  gd: {'A':'à', 'E':'è', 'I':'ì', 'O':'ò', 'U':'ù' },                   // Scottish Gaelic
  gn: {'A':'ã', 'E':'ẽ', /*'G':'g̃', */'I':'ĩ', 'N':'ñ', 'O':'õ', 'U':'ũ', 'Y':'ýỹ', '\'':'ʼ' }, // Guarani
  hu: {'A':'á', 'E':'é', 'I':'í', 'O':'öóő', 'U':'üúű'},                // Hungarian
  is: {'A':'áæ', 'D':'ðþ', 'E':'é', 'I':'í', 'O':'óö', 'U':'ú', 'Y':'ý'}, // Icelandic
  it: {'A':'àá', 'E':'èé', 'I':'ìí', 'O':'òó', 'U':'ùú'},               // Italian
  nl: {'A':'á', 'E':'éèë', 'I':'ï', 'O':'óö', 'U':'ü'},                 // Dutch (Netherlands)
  no: {'A':'åæ', 'O':'ø', 'E':'é'}, // Norwegian
  pl: {'A':'ą', 'C':'ć', 'E':'ę', 'L':'ł', 'N':'ń', 'O':'ó', 'S':'ś', 'Z':'żź'}, // Polish
  pt: {'A':'ãáâà', 'E':'éê', 'I':'í', 'O':'õóô', 'U':'úü', 'C':'ç'},    // Portuguese
  ro: {'A':'ăâ', 'I':'î', 'S':'şș', 'T':'ţț'},                          // Romanian
  sk: {'A':'áä', 'C':'č', 'D':'ď', 'E':'é', 'I':'í', 'L':'ľĺ', 'N':'ň', 'O':'óô', 'R':'ŕ', 'S':'š', 'T':'ť', 'U':'ú', 'Y':'ý', 'Z':'ž'}, // Slovak
  sv: {'A':'åä', 'O':'ö', 'E':'é'},                                     // Swedish
  tr: {'C':'ç', 'G':'ğ', 'I':'ıİ', 'O':'ö', 'S':'ş', 'U':'ü'},          // Turkish
};

export const underscoresToCamelCase = (s) => s.replace(/_(.)/g, (m, c) => c.toUpperCase());

export const tatoebaUrl = (id) => ("https://tatoeba.org/eng/sentences/show/" + id);

export function progressChunksExplainerText(perChunk) {
  return `How do you eat an elephant? 🐘 One bite at a time. These progress bars show your progress in chunks of ${perChunk} sentnces at a time. You can change your chunk size or switch to absolute progress bars via your Dashboard settings at the bottom of the Dashboard.`;
};

// https://stackoverflow.com/a/15289883
const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// a and b are javascript Date objects
export function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

// https://stackoverflow.com/questions/12006095/javascript-how-to-check-if-character-is-rtl
export function isRTL(s) {
  const ltrChars = 'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF'+'\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF';
  const rtlChars = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC';
  const rtlDirCheck = new RegExp('^[^'+ltrChars+']*['+rtlChars+']');

  return rtlDirCheck.test(s);
}

export function installGoogleAds() {
  if(window.testEnv) {
    return true;
  }

  if(document.querySelector("#google-ads-script")) {
    return true;
  }

  const elem = document.createElement("script");
  elem.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
  elem.async = true;
  elem.defer = true;
  elem.id = "google-ads-script";
  document.body.insertBefore(elem, document.body.firstChild);
}
